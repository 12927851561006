<template>
  <div>
    <loader :loading="loading"/>

    <page-header :title="`Portais`">
      <template v-slot:breadcrumbs>
        <li>Portais</li>
      </template>

      <template v-slot:actions>
        <div>
          <button class="btn btn-success">Novo Portal</button>
        </div>
      </template>
    </page-header>

    <filters @search="handleSearch(filters, 1)" @clear="clearFilters">
      <form @keyup.enter="handleSearch(filters)">
        <div class="row g-2 mt-2">
          <div class="col-4">
            <div>
              <label class="form-label">Nome</label>
              <input type="text" class="form-control form-control-sm" v-model="filters.name">
            </div>
          </div>

          <div class="col-2">
            <label class="form-label">Padrão</label>
            <select class="form-select form-select-sm" v-model="filters.spreader">
              <option value="" selected>Todos</option>
              <option v-for="spreader in spreaders" :key="spreader.id" :value="spreader.id">{{ spreader.name }}</option>
            </select>
          </div>

          <div class="col-2">
            <label class="form-label">Situação</label>
            <select class="form-select form-select-sm" v-model="filters.status">
              <option value="">Todos</option>
              <option value="1">Ativos</option>
              <option value="0">Inativos</option>
            </select>
          </div>
        </div>
      </form>
    </filters>

    <div class="card bg-white mt-4">
      <div class="card-body p-4">
        <div class="d-flex align-items-center justify-content-end">
          <span class="small text-secondary" v-if="collection.data.length">
            Mostrando {{ collection.data.length }} de {{ collection.meta.total }} registros
          </span>
        </div>
        <div class="table-responsive mt-3">

          <table class="table table-striped" v-if="collection.data.length">
            <thead>
            <tr>
              <th>Nome</th>
              <th class="text-center">Padrão de XML</th>
              <th class="text-center">Situação</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="portal in collection.data" :key="portal.id">
              <td>{{ portal.name }}</td>
              <td class="text-center">{{ portal.spreader.name }}</td>
              <td class="text-center">
                <span class="badge bg-success">Ativo</span>
              </td>
              <th>
                <div class="d-flex align-items-center justify-content-end">
                  <div class="btn-group">
                    <button type="button" class="btn btn-primary btn-sm dropdown-toggle"
                            data-bs-popper-config='{"strategy":"fixed"}' data-bs-toggle="dropdown">
                      Ações
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">Detalhes</a></li>
                      <li><a class="dropdown-item" href="#">Editar</a></li>
                      <li><a class="dropdown-item" href="#">Acessar</a></li>
                      <li>
                        <hr class="dropdown-divider">
                      </li>
                      <li><a class="dropdown-item text-danger" href="#">Inativar</a></li>
                    </ul>
                  </div>
                </div>
              </th>
            </tr>
            </tbody>
          </table>

          <div class="bg-light p-2 rounded-2 text-center" v-else>Nenhum registro encontrado...</div>

          <div class="d-flex align-items-center justify-content-center mt-4">
            <paginator
                :current-page="collection.meta.current_page"
                :limit-pages="5"
                :pages="collection.meta.last_page"
                @page-changed="handleSearch(filters, $event)"
                v-if="collection.data.length"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import Paginator from "@/components/shared/paginator.vue";
import Loader from "@/components/shared/loader.vue";
import axios from "axios";
import {mapActions, mapState} from "vuex";
import {filter} from "core-js/internals/array-iteration";
import Filters from "@/components/shared/Filters.vue";

export default {
  name: 'clients',
  props: {},
  components: {
    Filters,
    Loader,
    Paginator,
    PageHeader
  },
  data() {
    return {
      loading: false,
      filters: {
        name: null,
        status: 1,
        spreader: ''
      }
    }
  },
  async mounted() {
    await this.handleSearch(this.filters);
    await this.getSpreaders();
  },
  methods: {
    filter,
    ...mapActions('Portals', ['getAll', 'getSpreaders']),
    async handleSearch(filters, page = 1) {
      this.loading = true;
      const params = {
        page: page,
        ...filters
      }

      await this.getAll(params).finally(() => {
        this.loading = false;
      })
    },
    clearFilters() {
      this.filters = {
        name: null,
        status: 1,
        spreader: ''
      }

      this.handleSearch(this.filters, 1);
    }
  },
  computed: {
    ...mapState('Portals', ['collection', 'spreaders'])
  }
}
</script>
<style lang="scss" scoped>

</style>
